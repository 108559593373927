import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';


class Faq extends Component {

    render() {

        let data = sectiondata.faq
        let customclass = this.props.customclass ? this.props.customclass : ''

    return <div>
            <div className={ "faq-area "+customclass }>
              <div className="container">
                <div className="row custom-gutters-60 justify-content-center">
                  <div className="col-xl-9 col-lg-11">
                    <div className="section-title text-center">
                      <h2 className="title">{ data.sectiontitle } <span>{ data.sectiioncolor }</span></h2>
                      <p>{ data.sectionDescription }</p>
                    </div>
                    <div className="accordion" id="accordion">
                     { data.items.map( ( item, i ) =>
                          <div key={ i } className="single-accordion card">
                          <div className="card-header" id={"headingOne"+i}>
                            <h2 className="mb-0">
                              <button className="btn btn-link" type="button" data-toggle="collapse" data-target={"#collapseOne"+i} aria-expanded="true" aria-controls="collapseOne">
                                { item.title }
                              </button>
                            </h2>
                          </div>
                          <div id={"collapseOne"+i} className={"collapse "+ item.class} aria-labelledby="headingOne" data-parent="#accordion">
                            <div className="card-body">
                            { item.content }
                            </div>
                          </div>
                        </div>
                      ) }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-10">
                    <div className="section-title text-center margin-bottom-90">
                      <h2 className="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">Guarda come funzione <span><a className="title" href="https://scribehow.com/page/TrucksOut_101_come_dominare_il_gestionale__VRWvz3tISKKspg6pCzkM7Q" target="_blank" rel="noopener noreferrer">
                        <u>la nostra piattaforma</u>
                      </a></span></h2>
                      <p className="wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.2s"></p>
                      <h2 className="title wow animated fadeInUp" data-wow-duration="0.6s" data-wow-delay="0.1s">Oppure <span><a className="title" href="https://calendly.com/ronald-trucksout/30min" target="_blank" rel="noopener noreferrer">
                        <u>Prenota una Demo Gratuita</u>
                      </a></span></h2>
                    </div>
                </div>
            </div>
         </div>
    }
}

export default Faq